<template>
  <div class="app">
    <div class="topBananr">
      <p class="title">职业技能培训/考证</p>
      <p class="des">培训从事各个领域技术人才</p>
    </div>
    <div class="advantage">
      <div class="common-title">
        <p>学校简介</p>
        <span></span>
      </div>
      <div class="common-content clearfix">
        <el-row :gutter="10">
          <el-col :span='10'>
            <img src="../assets/images/about_img.jpg" alt="" @error="testFun" style="max-width:90%; min-width:90%"
              class="wow zoomIn">
          </el-col>
          <el-col :span='14'>
            <div class="whoText wow zoomIn">
              郴州博泰职业技能培训学校经郴州市行政审批服务局办学许可批复，批准文号：郴行审许培准字〔2021〕16号，经郴州市行政审批服务局营业登记，统一社会信用代码91431000MA7ALJ9Y0E。
              博泰培训，创于2021年7月，坐落于郴州市北湖区燕泉街道燕泉路中天大厦内，硬件设施齐全，师资力量雄厚，环境良好。拥有独立办公场地，设有教学理论室、实训室、校长室、讲师室、档案室等。培训项目涵盖初、中级计算机培训、家政服务培训、电工培训，年培训能力500人次。
              博泰培训，确立以市场为导向，以社会需求为目标的办学理念和专业设置原则，面向市场，服务社会，依法办事，从严治校。真正做到为学员考虑、让企业放心、对社会负责，培训从事各个领域的技术人才，更好的为郴州市经济发展和服务。
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <!-- 课程中心 -->
    <div class="curriculumList">
      <div class="common-title">
        <p>课程中心</p>
        <span></span>
      </div>
      <div class="curriculumBox clearfix pcShow">
        <VueSlickCarousel class="indexImgList" :slidesToShow='3' :slidesToScroll="1" :arrows="true" :dots="true"
          :autoplay='true' :autoplaySpeed='3000'>
          <div class="bg" v-for="(item, index) in curriculum" :key="'curr_' + index"
            @click="$router.push({ path: '/articles', query: { 'tag_id': item.id } })">
            <p><img :src="item.icon" alt=""></p>
            <p class="title">{{ item.title }}</p>
            <p class="des">{{ item.des }}</p>
          </div>
        </VueSlickCarousel>
      </div>
      <div class="curriculumBox clearfix wapShow">
        <VueSlickCarousel class="indexImgList" :slidesToShow='1' :slidesToScroll="1" :arrows="true" :dots="true"
          :autoplay='true' :autoplaySpeed='3000'>
          <div class="bg" v-for="(item, index) in curriculum" :key="'curr_' + index"
            @click="$router.push({ path: '/articles', query: { 'tag_id': item.id } })">
            <p><img :src="item.icon" alt=""></p>
            <p class="title">{{ item.title }}</p>
            <p class="des">{{ item.des }}</p>
          </div>
        </VueSlickCarousel>
      </div>
      <div>
        <el-button type="warning" @click="$router.push({ path: '/articles' })">更多课程</el-button>
      </div>
    </div>
    <!-- 新闻资讯 -->
    <div class="news">
      <div class="common-title">
        <p>学校新闻</p>
        <span></span>
      </div>
      <div class="new-bg">
        <div class="common-content clearfix">
          <!-- 新闻资讯内容 -->
          <ul>
            <li v-for="(item, index) in news" :key="index">
              <div class="new-content-item-img">
                <img :src="item.cover" alt>
                <p class="time">
                  <b>{{ item.day }}</b>
                  {{ item.yue }}
                </p>
              </div>
              <div @click="$router.push({ name: 'Newsdetail', params: { id: item.id } })" v-if='item.type === 1'
                class='cursor'>
                <!-- type: 1 站内新闻 -->
                <h4>{{ item.title }}</h4>
                <p class="des">{{ item.digest }}</p>
              </div>
              <div class='cursor' v-else> <!-- type: 2 站外新闻 -->
                <a :href="item.link" target="_blank">
                  <h4>{{ item.title }}</h4>
                  <p class="des">{{ item.digest }}</p>
                </a>
              </div>
            </li>
          </ul>
        </div>
        <div style="text-align:center;">
          <el-button type="warning" @click="$router.push({ path: '/news' })">更多新闻</el-button>
        </div>

        <!-- <div class="arrow-container clearfix"> -->
        <!-- 箭头 left -->
        <!-- <span class="arrow-left arrow" @click="previousPage">
              <svg class="icon myIconStyle" aria-hidden="true">
                <use xlink:href="#icon-jiantou"></use>
              </svg>
            </span>-->

        <!-- 箭头 right -->
        <!-- <span class="arrow-right arrow" @click="nextPage">
              <svg class="icon myIconStyle" aria-hidden="true">
                <use xlink:href="#icon-jiantou"></use>
              </svg>
            </span> -->
        <!-- </div> -->
      </div>
    </div>
  </div>
</template>
<script>
// import { setTimeout } from 'timers'
import VueSlickCarousel from 'slick-vuejs';
import 'slick-vuejs/dist/slick-vuejs.css';
import { WOW } from 'wowjs';
// optional style for arrows & dots
import 'slick-vuejs/dist/slick-vuejs-theme.css';

export default {
  components: {
    VueSlickCarousel
  },
  data() {
    return {
      banner: [],
      product: [],
      parner: [],
      // 新闻列表
      news: [],
      // 新闻当前页面
      page: 1,
      // 新闻最后一页
      lastPage: 1,
      swiperHeight: '',
      clientWidth: 0,
      isShow: true,
      curriculum: [
        { icon: require('../assets/images/icon_d.png'), title: '电工课程', des: '' },
        { icon: require('../assets/images/icon-web.png'), title: '计算机课程', des: '' },
        { icon: require('../assets/images/icon_home.png'), title: '家政课程', des: '' },
        // {icon:require('../assets/images/icon_xin.png'), title:'心理咨询师课程',des:''},
        // {icon:require('../assets/images/icon_xiaofang.png'), title:'消防应急救援课程',des:''}
      ],
      settings: {
        "dots": true,
        "edgeFriction": 0.35,
        "infinite": false,
        "speed": 500,
        "slidesToShow": 3,
        "slidesToScroll": 1,
        "autoplay": true
      }
    }
  },
  methods: {
    getTagData() {
      let _this = this;
      this.$store.dispatch('getTag').then(res => {
        if (res) {
          res.forEach((item, index) => {
            _this.curriculum.forEach(list => {
              if (list.title == item.tag_name) {
                list.des = item.tag_desc;
                list.id = item.id
              }
            })
          })
        }
      });
    },
    testFun() {
      this.isShow = false
    },
    // 获取首页新闻列表数据
    getNews(url) {
      this.$store.dispatch('getNews', { url: url }).then(res => {
        if (res.data.length > 0) {
          res.data.forEach(item => {
            item.timeDay = item.pub_date.split('-');
            item.day = item.timeDay[2];
            item.yue = item.timeDay[0] + '-' + item.timeDay[1];
            this.news.push(item)
          })
        }
        // 处理页面
        this.page = res.current_page
        // 最后一页
        this.lastPage = res.last_page
      })
    },
  },
  mounted() {
    /* wowjs动画 */
    var wow = new WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 0,
      mobile: true,
      live: true
    })
    wow.init();
    // 获取新闻列表
    this.getNews();
    //课程中心数据
    this.getTagData();
  }
}
</script>
<style scoped lang="less">
::v-deep {
  p {
    margin: 0 !important;
    padding: 0 !important;
  }
}

// index 公共
// .common-content {
//   width: 1200px;
//   margin: 0 auto;
//   text-align: center;
.topBananr {
  width: 100%;
  min-height: 600px;
  text-align: center;
  background: url('../assets/images/top.png') center center no-repeat;

  p {
    padding: 0;
    margin: 0;
  }

  .title {
    height: 63px;
    font-size: 64px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #FFFFFF;
    padding-top: 239px !important;
    line-height: 0.4;
  }

  .des {
    font-size: 25px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
  }
}

.curriculumList {
  width: 100%;
  min-height: 695px;
  text-align: center;
  background: url('../assets/images/bananr_blue.png') center center no-repeat;
  margin-top: 66px;

  .common-title {
    padding-top: 60px;

    p {
      color: #fff;
    }

    span {
      background: #fff
    }
  }

  .curriculumBox {
    width: 90%;
    max-width: 1230px;
    margin: 0 auto;
    padding: 30px 0;

    .carouselBox {
      width: 100%;
      height: 100%;
      margin: 0 auto;
      box-sizing: border-box;
      overflow: hidden;
      position: relative;
    }

    ::v-deep {
      .carousel-item {
        display: flex;
        justify-content: center;
      }

      .el-carousel__container {
        height: 400px;
      }

      .el-carousel__item {
        width: calc(33%);
        height: 358px;
        left: 45px;
        transform: scale(1);

        .el-carousel__mask {
          margin: 0 10px;
        }

        .bg {
          width: calc(100%);
          background: #fff;
          border-radius: 4px;
          height: 358px;

        }

        .title {
          color: #15181B;
          font-size: 24px;
          font-weight: bold;
          line-height: 2.3;
        }

        .des {
          font-size: 16px;
          color: #666666;
          line-height: 1.7;
          width: 90%;
          margin: 20px auto !important;
          text-align: left;
          text-indent: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }

        p img {
          padding-top: 30px;
        }
      }

      .el-carousel__arrow {
        background: transparent;
        font-size: 44px;
      }

      .el-carousel__arrow--right {
        right: -70px;
      }

      .el-carousel__arrow--left {
        left: -80px;
      }

      .el-carousel__indicators--outside {
        display: none;
      }

      .slick-next:before,
      .slick-prev:before {
        font-size: 35px
      }

      .slick-dots {
        display: none !important;
      }
    }
  }
}

.icon {
  width: 70px;
  height: 80px;
}

.iconfont {
  font-size: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.bule {
  color: #42a2e7;
}

/*img[src=""],img:not([src]) {opacity: 0}*/
// }
//banner样式
.home-swiper {
  width: 100%;

  .swiper-item {
    width: 100%;

    img {
      width: 100%;
    }
  }
}

//我们是谁
.whoText {
  text-align: left;
  line-height: 35px;
  color: #222222;
  text-indent: 40px;
  padding-left: 40px;
}

// 加入财库通优势
.advantage {
  .grid-content {
    display: flex;
    min-height: 130px;

    .item-content {
      flex: 1;
      text-align: left;
      margin-left: 20px;

      h4 {
        margin: 0;
      }

      p {
        margin: 0
      }
    }

  }
}

.indexImgList {
  // overflow: hidden;
  margin-bottom: 30px;

  .bg {
    width: 375px !important;
    background: #fff;
    border-radius: 4px;
    height: 358px;
    text-align: center;
    cursor: pointer;
  }

  .title {
    color: #15181B;
    font-size: 24px;
    font-weight: bold;
    line-height: 2.3;
  }

  .des {
    font-size: 16px;
    color: #666666;
    line-height: 1.7;
    width: 90%;
    margin: 20px auto !important;
    text-align: left;
    text-indent: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  p img {
    padding-top: 30px;
    width: 108px;
    margin: 0 auto;
  }
}

// 新闻资讯
.news {
  .new-bg {
    height: 670px;

    .common-content {
      width: 95%;
      max-width: 1230px;
      height: 580px;
      margin: 20px auto 0;
      overflow: hidden;
      position: relative;

      ul {
        display: flex;
        height: 100%;
        position: absolute;
        transition: left 1.1s linear;
        margin: 0;
        padding: 0;
        width: 100%;

        li {
          width: calc(33.3% - 36px);
          height: 509px;
          background: #FFFFFF;
          box-shadow: 0px 6px 25px 0px rgba(212, 213, 215, 0.85);
          border-radius: 8px;
          float: left;
          margin: 0 18px;

          &:last-child {
            margin-right: 0;
          }

          .new-content-item-img {
            height: 281px;
            width: 100%;
            margin-bottom: 15px;
            position: relative;

            img {
              display: inline-block;
              height: 100%;
              width: 100%;
              border-radius: 8px 8px 0px 0px;
            }

            .time {
              width: 76px;
              height: 60px;
              background: rgba(24, 159, 249, 0.8);
              display: block;
              position: absolute;
              bottom: -30px;
              left: 30px;
              z-index: 33;
              color: #fff;
              font-size: 16px;
              padding: 5px;
              text-align: center;

              b {
                display: block;
                font-size: 25px;
              }
            }
          }

          h4 {
            letter-spacing: 1px;
            text-align: left;
            padding: 60px 20px 20px 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin: 0;
            color: rgba(21, 24, 27, 1);
            font-size: 24px;
          }

          .des {
            // text-indent: 2em;
            margin: 0 20px 35px 20px !important;
            line-height: 25px;
            letter-spacing: 1px;
            font-size: 16px;
            color: rgba(102, 102, 102, 1);
            display: -webkit-box;
            -webkit-line-clamp: 3;
            overflow: hidden;
            -webkit-box-orient: vertical;
            text-align: left;
          }

          a {
            position: absolute;
            left: 50%;
            bottom: 25px;
            margin-left: -60px;
            display: block;
            width: 110px;
            height: 36px;
            line-height: 33px;
            border-radius: 4px;
            background: #e6a23c;
            // font-size: 15px;
            letter-spacing: 1px;
            text-align: center;
            color: #fff;
          }

          a:hover {
            background: #ebb563;
          }
        }
      }
    }

    .arrow-container {
      max-width: 1180px;
      position: relative;
      margin: 0 auto;

      .arrow {
        position: absolute;
        top: 45%;
        z-index: 99;

        &.arrow-left {
          transform: rotate(180deg);
          left: 4rem;
        }

        &.arrow-right {
          right: 4rem;
        }
      }

    }
  }
}

.pcShow {
  display: block;
}

.wapShow {
  display: none;
}

::v-deep {
  .slick-next {
    transform: rotate(-90deg);
    width: 50px;
    height: 50px;
    right: -50px;

    &:before {
      content: '\2335';
      font-size: 50px !important;
    }
  }

  .slick-prev {
    transform: rotate(90deg);
    width: 50px;
    height: 50px;
    left: -50px;

    &:before {
      content: '\2335';
      font-size: 50px !important;
    }
  }
}

// 合作伙伴
.customer {
  .el-col {
    height: 100px;

    a {
      box-sizing: border-box;
      border: 1px solid @global-gray;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        -webkit-appearance: none;
        /*opacity: 1;*/
        border: none;
      }
    }
  }
}

@media screen and (max-width: 1160px) and (min-width: 700px) {

  // 公共
  .common-content {
    width: 95%;
  }

  .indexImgList .bg {
    width: 285px !important;
  }

  // 新闻资讯
  .news {
    .common-content {
      width: 95% !important;
    }
  }
}

@media screen and (max-width: 1000px) and (min-width: 771px) {
  .news {
    .new-bg {
      .arrow-container {
        .arrow {
          &.arrow-left {
            left: 1rem;
          }

          &.arrow-right {
            right: 1rem;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 770px) and (min-width: 700px) {

  // 新闻资讯
  .news {
    .new-bg {
      .arrow-container {
        .arrow {
          &.arrow-left {
            left: 0rem;
          }

          &.arrow-right {
            right: 0rem;
          }
        }
      }
    }
  }

  // 合作伙伴
  .customer {
    .el-col {
      margin-bottom: 10px;
    }

    a {
      height: auto !important;

      img {
        border: none;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .wapShow {
    display: block;
  }

  .pcShow {
    display: none;
  }

  // 公共
  .common-content {

    // width: auto;
    .icon {
      width: 60px;
      height: 80px;
    }

    .item-content {
      p {
        font-size: 14px;
      }
    }
  }

  // 新闻资讯
  .news {
    .new-bg {
      height: auto;

      .arrow-container {
        .arrow {
          &.arrow-left {
            left: 2rem;
          }

          &.arrow-right {
            right: 2rem;
          }
        }
      }

      .common-content {
        width: 100%;
        height: auto;
        min-height: 580px;
        margin: 0;

        ul {
          position: relative;
          display: block;
        }

        ul li {
          width: 90%;
          float: none;
          margin: 15px auto;

          &:last-child {
            margin-right: auto;
            margin-bottom: 50px;
          }
        }
      }
    }
  }

  // 合作伙伴
  .customer {
    .el-col {
      margin-bottom: 10px;
    }

    a {
      height: 100%;

      img {
        border: none;

      }
    }
  }

  .topBananr {
    min-height: 260px;
    background-size: auto 100%;

    .title {
      padding-top: 100px !important;
      font-size: 34px;
      line-height: 2;
    }

    .des {
      font-size: 18px;
    }
  }

  .common-content {
    ::v-deep {

      .el-col-10,
      .el-col-14 {
        width: 100%;
      }
    }

    .whoText {
      padding: 0 20px;
      line-height: 2;
    }
  }

  .curriculumList {
    margin-top: 30px;
    min-height: 620px;

    .common-title {
      padding-top: 30px;
      margin-top: 0
    }

    .curriculumBox {
      width: calc(100% - 100px);
      padding: 0 35px;

      .bg {
        width: 90% !important;
        margin: 0 auto;
      }
    }
  }

  ::v-deep {
    .slick-prev {
      left: -30px;
    }

    .slick-next {
      right: -30px;
    }
  }
}

.cursor {
  cursor: pointer;
}

@media screen and (max-width: 460px) {

  // 新闻资讯
  .news {
    .new-bg {
      .arrow-container {
        .arrow {
          &.arrow-left {
            left: -.6rem;
          }

          &.arrow-right {
            right: -.6rem;
          }
        }
      }
    }
  }
}
</style>
